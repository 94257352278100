import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v2.0.0";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
//import App from './App';
import Landing from './views/LandingMaintenance.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Landing />
  </React.StrictMode>
);
