import React, { useState, useEffect } from "react";
// reactstrap components
import { Col, Row, Nav, NavItem, NavLink } from "reactstrap";

import bgImage from "../assets/img/login/backgroundLogin.jpg"; // Ajusta la ruta según la ubicación del archivo
import logoImage from "../assets/img/login/logoBlanco.png"; // Asegúrate de importar la imagen que quieres usar
import maintenance from "../assets/img/login/maintenance.png"; // Asegúrate de importar la imagen que quieres usar

const LandingMaintenance = () => {
  const [infoMaintenance, setInfoMaintenance] = useState({
    eventTitle: "Migración de servidores RHEL ",
    eventDateInit: "Sábado 14 de diciembre 08:00 am",
    eventDateEnd: "Sábado 14 de diciembre 10:00 pm",
  });
  // Define los estilos de la columna de la imagen.
  const colImageStyle = {
    position: "relative",
    backgroundImage: `url(${bgImage})`, // Utiliza la imagen importada
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Ajusta la altura según sea necesario
    color: "white", // Color del texto
  };
  // Estilo para el texto con efecto de difuminado
  const textStyle = {
    fontSize: "1.45rem",
    background:
      "linear-gradient(to right, white 85%, rgba(255, 255, 255, 0) 100%)", // Gradiente de blanco a transparente al final
    WebkitBackgroundClip: "text", // Aplica el gradiente al texto
    WebkitTextFillColor: "transparent", // Hace el texto transparente para mostrar el gradiente
    color: "white", // Texto blanco en los navegadores que no soportan WebKit
    display: "inline", // Asegura que el gradiente se aplique solo al texto
    margin: 0, // Ajusta márgenes si es necesario navegadores que no soportan WebKit
  };
  //Estilos de la columna del botón de login.
  const colLoginStyle = {
    backgroundImage:
      "linear-gradient(to bottom right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1))",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Ajusta la altura según sea necesario
    color: "white", // Color del texto
    padding: "20px", // Ajusta el relleno si es necesario
  };

  // Estilo para el contenedor del texto
  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Centra verticalmente
    alignItems: "center", // Centra horizontalmente
    height: "100%", // Asegura que el contenedor ocupe toda la altura de la columna
    textAlign: "center", // Centra el texto
  };

  useEffect(() => {
    document.body.classList.add("bg-default");
  }, []);

  return (
    <>
      <Row>
        <Col xs="6" className="d-none d-md-block" style={colImageStyle}>
          <div style={textContainerStyle}>
            <h6 className="surtitle" style={textStyle}>
              WE BUILD YOUR DIGITAL FUTURE:
            </h6>
            <img
              src={logoImage}
              alt="GBM Logo"
              style={{
                width: "130px",
                height: "auto",
                margin: "5px 0",
                marginTop: "0px",
              }}
            />
          </div>
        </Col>
        <Col xs="12" sm="6" className="" style={colLoginStyle}>
          <div style={textContainerStyle}>
            <Row>
              <Col sm="12" className="mt-4">
                <img
                  src={logoImage}
                  alt="GBM Logo"
                  style={{
                    width: "160px",
                    height: "auto",
                    margin: "5px 0",
                    marginTop: "0px",
                  }}
                />
              </Col>
              <Col sm="12" className="mt-4">
                <h4
                  style={{
                    fontSize: "1.50rem",
                    color: "white",
                  }}
                >
                  El sitio se encuentra en mantenimiento
                </h4>
                <br />
                <h5
                  style={{
                    fontSize: "1.0rem",
                    color: "white",
                  }}
                >
                  <strong
                    style={{
                      fontSize: "1.50rem",
                      color: "orange",
                    }}
                  >
                    {infoMaintenance.eventTitle}
                  </strong>
                </h5>
                <br />
                <h5
                  style={{
                    fontSize: "1.0rem",
                    color: "white",
                  }}
                >
                  El mantenimiento se llevará a cabo desde:{" "}
                  <strong
                    style={{
                      color: "orange",
                    }}
                  >
                    {infoMaintenance.eventDateInit}
                  </strong>{" "}
                  - hasta:{" "}
                  <strong
                    style={{
                      color: "orange"
                    }}
                  >
                    {infoMaintenance.eventDateEnd}
                  </strong>
                </h5>
              </Col>
              <Col sm="12" className="mt-4">
                <img
                  src={maintenance}
                  alt="maintenance"
                  style={{
                    width: "120px",
                    height: "auto",
                    margin: "5px 0",
                    marginTop: "0px",
                  }}
                />
              </Col>
            </Row>
          </div>
          <Row
            style={{
              alignItems: "center", // Centra horizontalmente
            }}
          >
            <Col
              className=" mt--4"
              sm="12 "
              style={{
                display: "flex",
                flexDirection: "column", // Alinea los elementos en una columna
                justifyContent: "flex-end", // Alinea los elementos al final (parte inferior)
                height: "90%", // Ocupa toda la altura de la vista
                alignItems: "center", // Centra horizontalmente
              }}
            >
              <Nav className="copyright text-center text-xl-left text-muted">
                <NavItem>
                  <NavLink target="_blank">
                    © {new Date().getFullYear()} GBM Digital
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LandingMaintenance;
